@import "nlib/config";

.taskContent {
  position: relative;
  padding: $contentDefaultIndent * 1.5;
  border-radius: $uiBorderRadius;
  background-color: $uiWhiteColor;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: $contentDefaultIndent;
    bottom: $contentDefaultIndent;
    left: 0;
    width: 2px;
    border-radius: 0 2px 2px 0;
    background-color: transparent;
  }
  .preview {
    margin-bottom: $contentDefaultIndent;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;
    &[data-value] {
      &::before {
        content: "";
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: $contentDefaultIndent / 2;
        border: 0.25rem solid mix($uiMediumDarkColor, $uiWhiteColor, 15);
        border-radius: 50%;
        background-color: lighten($uiMediumDarkColor, 22);
      }
    }
    &[data-value=inProgress] {
      &::before {
        border-color: mix($uiWarningColor, $uiWhiteColor, 15);
        background-color: $uiWarningColor;
      }
    }
    &[data-value=onHold] {
      &::before {
        border-color: mix($uiNegativeColor, $uiWhiteColor, 15);
        background-color: $uiNegativeColor;
      }
    }
    &[data-value=completed] {
      &::before {
        border-color: mix($uiPositiveColor, $uiWhiteColor, 15);
        background-color: $uiPositiveColor;
      }
    }
  }
  .createdBy {
    margin-bottom: $contentDefaultIndent;
    color: $uiMediumDarkColor;
    font-size: 0.75rem;
  }
  .info {
    display: flex;
    .infoItem {
      width: 14.2857%;
      margin-bottom: $contentDefaultIndent;
      .infoItemTitle {
        padding-right: $contentDefaultIndent;
        color: $uiMediumDarkColor;
        font-size: 0.75rem;
      }
      .infoItemValue {
        padding-right: $contentDefaultIndent;
        font-size: 0.75rem;
        word-break: break-all;
        svg {
          color: $uiMediumDarkColor;
          font-size: 1rem;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    .item {
      &:last-child {
        margin-left: auto;
      }
      &.attachments,
      &.comments {
        min-width: 3.25rem;
        margin-right: $contentDefaultIndent;
        svg {
          font-size: 1.5rem;
        }
      }
    }
    .remove {
      svg {
        color: $uiNegativeColor;
      }
    }
  }
  &.completed {
    .preview {
      color: $uiMediumDarkColor;
      text-decoration: line-through;
    }
    .infoItem {
      opacity: 0.75;
    }
    .footer {
      .attachments,
      .comments {
        opacity: 0.75;
      }
    }
  }
}

[data-mobile] .taskContent {
  @media (max-width: $viewportSizeExtraLarge) { // 1200px
  }

  @media (max-width: $viewportSizeLarge) { // 992px
  }

  @media (max-width: $viewportSizeMedium) { // 768px
    .info {
      flex-wrap: wrap;
      .infoItem {
        width: auto;
        margin-right: $contentDefaultIndent;
      }
    }
  }

  @media (max-width: $viewportSizeSmall) { // 576px
  }

  @media (max-width: $viewportSizeExtraSmall) { // 420px
  }
}
