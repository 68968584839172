@import "nlib/config";

.listItemDetails {
  position: fixed;
  z-index: 19;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
