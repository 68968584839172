@import "nlib/config";

.tasksPage {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: $mobileNavBarHeight;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $contentDefaultIndent 0 $contentDefaultIndent * 3;
  }
  .item {
    margin-top: $contentDefaultIndent;
    margin-bottom: $contentDefaultIndent;
  }
}
